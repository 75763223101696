.email-request-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-request-backdrop .email-request-modal {
    background-color: white;
    border : 2px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    padding: 20px;
    border-radius: 15px;
    z-index: 1000;
    min-height: 150px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
}

.email-request-backdrop .email-request-modal .modal-top {
    position: relative;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email-request-backdrop .email-request-modal .modal-top h1,
.email-request-backdrop .email-request-modal p {
    padding: 0;
    margin: 0;
}

.email-request-backdrop .email-request-modal .modal-top .close-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.email-request-backdrop .email-request-modal  .input-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    
}

.email-request-backdrop .email-request-modal  .input-box input {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.email-request-backdrop .email-request-modal button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
    background-color: var(--primary-color);
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
