.top-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 5px 0px;
    background-color: #15629b;
    color: white;
    user-select: none;
}

.top-container .menu-icon {
    position: absolute;
    right: 20px;
    height: 35px;
    width: auto;
    cursor: pointer;
}

.top-container .menu-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    padding: 0px 10px;
    cursor: pointer;
}

.top-container .menu-content {
    z-index: 1000;
    top: 55px;
    right: 25px;
    border-radius: 10px;
    padding: 5px 40px;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
}

.top-container .menu-content button {
    height: 40px;
    color: black;
    font-weight: 500;
}

.top-container .logout-icon {
    width: 30px;
    height: 40px;
    position: absolute ;
    right: 10px;
}

.top-container .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.top-container .se-logo {
    height: 40px;
    width: auto;
    position: absolute;
    left: 10px;

    /* responsiveness */
    @media (width <= 840px) {
        display: none;
    }
}
