.body-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    background-color: white;

    /* responsiveness */
    @media ( width > 1280px) {
        width: calc(100% - 150px);
    }
    @media (width < 1280px) {
        width: calc(100% - 50px) !important;
    }
}

.body-container .filter-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    height: 57px;
    max-height: 57px;
    min-height: 57px;
    padding: 0px 0px;
    width: calc(100% - 20px);
    margin: 10px;
    user-select: none;
    background-color:#15629b;
    /* responsiveness */
    @media (width <= 650px) {
        flex-direction: column;
        align-items: flex-start;
        max-height: 107px;
        min-height: 107px
    }
}

.body-container .filter-search-container .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 55px;
    padding: 0px 0px;
    
}

.body-container .filter-search-container .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px;
    height: 100%;
    padding: 0px 0px;

    /* responsiveness */
    @media (width <= 830px) {
        padding: 0px 6px;
    }
}

.body-container .filter-search-container .right .icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: auto;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    border: 1px solid lightgray;
    margin: 0px 7.5px;
    gap: 10px;
     background-color: #f5f5f5;
}
.body-container .filter-search-container .right .notification-icon,
.body-container .filter-search-container .right .settings-icon {
    height: 100%;
    width: auto;
    cursor: pointer;
    color: gray;
    
}

.body-container .filter-search-container .search-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: auto;
    border-radius: 5px;
    padding: 5px 0px 5px 5px;
    border: 1px solid lightgray;
    margin: 0px 7.5px;
     background-color: #f5f5f5;
}
.body-container .filter-search-container .search-container .search-icon {
    height: 80%;
    width: auto;
    cursor: pointer;
    color: gray;
}

.body-container .filter-search-container .search-container .search-input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    margin-left : 5px;
    font-size: 15px;
     background-color: #f5f5f5;
}

.body-container .filter-search-container .text-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: auto;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    border: 1px solid lightgray;
    margin: 0px 0px;
    color: gray;
     background-color: #f5f5f5;
}

.body-container .filter-search-container .search-type-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: auto;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    border: 1px solid lightgray;
    margin: 0px 0px;
     background-color: #f5f5f5;
    outline: none;
} 

.body-container .filter-search-container .search-type-dropdown select {
    border: none;
    outline: none;
    
    font-size: 15px;
    color: gray;
    width: auto;
    cursor: pointer;
    -moz-appearance:none !important;
     background-color: #f5f5f5;
    
}

.body-container .filter-search-container .date-input {
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: auto;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    border: 1px solid lightgray;
    color: gray;
    outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator  {
    color: gray;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

