

.body-container .table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    margin-bottom: 10px;
    border-radius: 15px;
    overflow-y: scroll;
    border: 1px solid lightgray;
    scrollbar-width: none;
    -ms-overflow-style: none;
    z-index: 0;
}

.body-container .table .table-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 38px;
    max-height: 38px;
    padding: 5px 0px 5px 0px;
    background-color: whitesmoke;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid lightgray;
    position: sticky;
    top: 0;
    user-select: none;
}

.body-container .table .table-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    padding: 5px 0px 5px 0px;
    border-bottom: 1px solid lightgray;
    color: gray;
    cursor: pointer;
}

.body-container .table .table-row-active {
    background-color: #f5f5f5;
    transition: all ease 0.3s;
    color: black
}

.body-container .table .table-row .table-cell,
.body-container .table .table-header .table-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 15px 0px 15px;
    flex: 1;
}

.body-container .table .table-row .id,
.body-container .table .table-header .id {
    flex: 0.5;
} 

.body-container .table .table-row .table-cell .download-icon,
.body-container .table .table-header .table-cell .download-icon {
    height: 60%;
    cursor: pointer;
    z-index: 10000;
}

.body-container .table .table-row .time,
.body-container .table .table-header .time {
    border-right: none;


}

.body-container .table .table-header .cell-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;

}
.body-container .table .table-header .sort-icon {
    height: 16px;
    cursor: pointer;
}


.body-container .table .table-row .table-cell .download-section {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.body-container .table .table-header  .status,
.body-container .table .table-row .status {
    text-align: center;
    justify-content: center;
    flex: 0.2;
    border-right: none;
}

.body-container .table .table-header  .status .cell-text {
    justify-content: center;
}

.body-container .table .table-row .status .status-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.body-container .table .loading-icon {
    margin-top: 100px;
}
