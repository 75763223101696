.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-width: 200px;
    max-width: 200px;
    background-color: white;
    border-right: 1px solid lightgray;
    user-select: none;
    }



.sidebar-container .sidebar-content {
    width: calc(100% - 20px);
}

.sidebar-container .sidebar-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 10px 0px 0px 0px;
    height: 57px;
    width: calc(100%);
    border-radius: 10px;
    border: 1px solid lightgray;
     background-color: #15629b;
    color: white;
    cursor: default;
    transition: all 0.3s;
    gap: 10px;
    padding: 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.mobile-sidebar .sidebar-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 10px 0px 0px 10px;
    height: 57px;
    width: calc(100% - 25px);
    border-radius: 10px;
    border: 1px solid lightgray;
    background-color: #15629b;
    color: white;
    cursor: default;
    transition: all 0.3s;
    gap: 10px;
    padding: 0px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    

}

.sidebar-container .sidebar-item h4,
 .mobile-sidebar .sidebar-item h4{
    margin: 0px;
    padding: 0px;
    font-weight: 500;

}

.sidebar-container .sidebar-item:hover ,
 .mobile-sidebar .sidebar-item:hover {
    background-color: #15629b;
    color: white;
    
}


.sidebar-container .faust-logo {
    padding: 10px 10px;
    height: 50px;
    width: 120px;   
}

.mobile-sidebar .faust-logo {
    position: relative;
    padding: 10px 10px;
    height: 50px;
    width: 120px;
    left: calc(50% - 70px);
}


 .mobile-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-width: 50px;
    max-width: 50px;
    background-color: white;
    border-right: 1px solid lightgray;
    
    
}
 .mobile-sidebar .menu-slider {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 10px 10px 0px 10px;
    height: 57px;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid lightgray;
    border-right: none;
    background-color: white;
    cursor: pointer;
    transition: all 0.3s;
    cursor: pointer;
    gap: 10px;
    padding: 0px 0px;
    width: 40px;

}

.mobile-sidebar .sidebar-header {
    position: absolute;
    left: 10px;
    top: 3px;
}


.mobile-sidebar .sidebar-header h4 {
    margin: 0px;
    padding: 0px;
    font-weight: 500;
    color: gray;
}

.mobile-sidebar .overlay {
    position: absolute;
    
    width: calc(100vw - 200px);
    height: 100%;
    left: 100%;

}

.mobile-sidebar .menu-icon {
    width: 50%;
    color: gray
}

#collapsed {
    left: calc(0px);
    transition: left 0.5s;
}

#expanded {
    border-radius: 0px 10px 10px 0px;
    border: 1px solid lightgray;
    border-left: none;
    left: calc(100% - 10px);
    transition: left 0.3s;
}

#slide-in {
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 70px);
    z-index: 1000;
    min-width: 50px;
    transition: all 0.4s;
}

#slide-out {
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 70px);
    z-index: 1000;
    min-width: 200px;
    transition: all 0.4s;
}
