.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100dvh
}

.dashboard-container .dashboard-divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    background-color: #f5f5f5;
}