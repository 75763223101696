.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    
    
}

.loading-container .loading-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    background-color: #15629b;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}

.loading-container .loading-box .se-logo {
    width: 400px;
    height: 65px;
    margin: 10px 10px;
   
}