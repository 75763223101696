/* Autofill color removed */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    border-radius: 7.5px;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    background-color: #f5f5f5;
    overflow: hidden;
}

.login-container .login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    max-height: 598.25px;
    min-height: 598.25px;
    height: auto;
    padding:0px 95px ;
    border-radius: 15px;
    gap: 20px;
    background : url('../assets/background.svg' ) no-repeat center center fixed;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    transition: fade 0.5s;

    /* responsiveness */
    @media (width <= 668px) {
        box-shadow: none;
        height: 100%;
        border: none;
        border-radius: 0px;
        max-width: 100vw;
    };
}
    

.login-container .login-content img {
    width: 400px;
    height: auto;
    margin-bottom: 30px !important;
}

.login-container .login-content .title {
    font-size: 36px;
    padding: 0;
    margin: 0;
    color: #f5f5f5;
}

.login-container .login-content p {
    font-size: 16px;
    color: #f5f5f5;
    cursor: pointer;
    padding: 0;
    margin: 0;

}

/* Input Styling */
.login-container .login-content .input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 59px;
    border-radius: 7.5px;
    color: black;
    background-color: #f5f5f5;
}

.login-container .login-content .input-box input {
    height: 100%;
    margin: 0;
    padding: 0 25px;
    border: none;
    outline: none;
    font-size: 16px;
    color: black;
    background-color: transparent;
    width: 100%
}

.login-container .login-content .input-box .icon {
    height: 50%;
    cursor: pointer;
    stroke: gray;
}

.login-container .login-content .password {
    padding-right: 25px;
}

/* Button Styling */
.login-container .login-content .login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 59px;
    margin-top: 15px;
    border: none;
    outline: none;
    font-size: 20px;
    color: black;
    background-color: lightgray;
    cursor: pointer;
    border-radius: 7.5px;
}

.login-container .login-content .login-btn:hover {
    background-color: #f5f5f5;
}