.not-found-container {
    height: 100dvh;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #15629b;
}

.not-found-container .logo-container {
    position: absolute;
    top: 10px;
    left: 10px;
}

.not-found-container  .error-code {
    font-size: 15rem;
    color: white;
    margin: 0;
}

.not-found-container .error-message {
    font-size: 3rem;
    color: white;
    margin-top: 0;
}

.not-found-container .se-logo {
    margin: 10px 10px;
    width: 300px;
    height: 50px;
    animation: pulse 2s linear infinite;
}

.not-found-container .navigate-home {
    width: 300px;
    height: 5%;

    top: 10px;
    left: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--primary);
    color: black;
    background-color: white;
    font-size: 1rem;
    cursor: pointer;
}

.not-found-container .navigate-home:hover {
    scale: 1.05;
    transition: scale 0.2s ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

