.table .edit-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

}

.edit-modal {
    position: absolute;
    background-color: white;
    border : 2px solid lightgray;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    padding: 20px;
    border-radius: 15px;
    z-index: 1000;
}

.table .edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;

}

.table .edit-modal .edit-modal-content {
    min-height: 180px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    
}

.table .edit-modal .edit-modal-header {
    position: relative;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.table .edit-modal .edit-modal-header h2 {
    margin: 0;
}

.table .edit-modal .edit-modal-header .close-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.table .edit-modal .edit-modal-content .edit-modal-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.table .edit-modal .edit-modal-content .edit-modal-body input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgray;
    margin: 5px 0;
    height: calc(39px - 10px);
    font-size: 16px;
    width: calc(100% - 10px);
}

.table .edit-modal .edit-modal-content button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #15629b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    height: 39px;
    font-size: 16px;
}